import { Link } from "@/components";
import { IViewTableColumn } from "@/components/table/ViewTable";
import { routerMap } from "@/router/agent_router";
import WebApi from "@/web/api";
import React from "react";
import CommonReport from "../components/CommonReport";

export default class ProductSaleReportPage extends React.PureComponent {
  private summaryColumns = [
    {
      title: '进货单(笔)',
      field: 'totalOrderCount',
    },
    {
      title: '商品数量(主计量单位)',
      field: 'totalProductCount',
    },
    {
      title: '进货供应商(家)',
      field: 'totalCustomerCount',
    },
    {
      title: '进货总额(元)',
      field: 'totalPurchaseAmount',
      precision: 2
    },
  ];

  private columns: IViewTableColumn[] = [
    {
      title: '商品',
      dataIndex: 'productName',
      width: 200,
      align: 'left',
      // render: (value, row: any) => {
      //   if (row.orderCount == 0) {
      //     return value;
      //   }
      //   return <Link to={routerMap.reportSaleOrderCustomerDetail.getPath(row['dateRange'].map(i => i.replaceAll('-', '')).join('-'), row.customerId)}>{value}</Link>
      // }
    },
    {
      title: '进货单(笔)',
      dataIndex: 'orderCount',
      align: 'center',
      width: 120,
      sorter: (a: any, b: any) => a.orderCount - b.orderCount,
    },
    {
      title: '数量(主计量单位)',
      dataIndex: 'count',
      align: 'center',
      width: 160,
      sorter: (a: any, b: any) => a.count - b.count,
    },
    // {
    //   title: '供应商',
    //   dataIndex: 'customerCount',
    //   align: 'center',
    //   width: 120,
    // },
    // {
    //   title: '订货均价(元)',
    //   dataIndex: 'averageSalePrice',
    //   align: 'center',
    //   width: 120,
    // },
    {
      title: '进货均价(元)',
      dataIndex: 'averagePurchasePrice',
      align: 'center',
      width: 120,
      sorter: (a: any, b: any) => a.averagePurchasePrice - b.averagePurchasePrice,
    },
    // {
    //   title: '订货总额(元)',
    //   dataIndex: 'saleAmount',
    //   align: 'center',
    //   width: 120,
    // },
    {
      title: '进货总额(元)',
      dataIndex: 'purchaseAmount',
      align: 'center',
      width: 120,
      sorter: (a: any, b: any) => a.purchaseAmount - b.purchaseAmount,
    },
    // {
    //   title: '利润(元)',
    //   dataIndex: 'profitAmount',
    //   align: 'center',
    //   width: 120,
    // }
  ];
  render() {
    return <CommonReport
      filter={data => {
        return data.filter(i => i.orderCount > 0);
      }}
      showEmptyLabelText="仅显示有订单发生的商品"
      tableColumns={this.columns}
      pagination={false}
      summaryColumns={this.summaryColumns}
      fetch={data => WebApi.report_product_sale(data)}
    />
  }
}